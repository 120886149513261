import React from 'react'
import { graphql, useStaticQuery  } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Tilt from 'react-parallax-tilt';
import { Link } from "gatsby"
import PageTransition from 'gatsby-plugin-page-transitions';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    projects: allSanityProject(limit: 6, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }

    posts: allSanityPost(limit: 6, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs)
    : []
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <PageTransition>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <section>
          <h1 data-sal="slide-up" data-sal-delay="10" data-sal-duration="600" data-sal-easing="ease" className="gandolf">"All we have to decide is what to do with the time that is given to us."</h1>
        </section>

        <section>
          <div className="cta-box">
              <Tilt tiltReverse='true'>
                <Link to="/classes/" style={{ height: '400px', width: '300px', background: 'url(/images/cta-1.webp)', backgroundSize: 'cover' }}>
                  <span>On-Demand Classes</span>
                </Link>
              </Tilt>
              <Tilt tiltReverse='true'>
                <Link to="/blog/" style={{ height: '400px', width: '300px', background: 'url(/images/cta-2.webp)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                  <span>Blog</span>
                </Link>
              </Tilt>
              <Tilt tiltReverse='true'>
                <Link to="/about/" style={{ height: '400px', width: '300px', background: 'url(/images/cta-3.webp)', backgroundSize: 'cover' }}>
                  <span>About</span>
                </Link>
              </Tilt>
          </div>
        </section>

        <section id="welcome">
          <div className="hp-intro">
            <div className="hp-intro-image">
              <img alt="Yoga in the woods" src="/images/hp-circle.webp" />
            </div>
            <div className="hp-intro-text">
            <h1>Welcome to <br />
            Rock Yoga</h1>
            <p>My goal is to encourage and guide my students to live their best life, to live life with intention, and use yoga strategies off the mat. Yoga will not only help you become more physically fit, it will make you mentally stronger. If you can move and breathe, you can do yoga.</p>
            <Link className="btn-link" to="/classes/">View Our Classes</Link>
            </div>
          </div>
        </section>

        {/* <section className="newsletter">
          <h2>Newsletter</h2>
        </section> */}

        {/* <section className="hp-classes" data-sal="slide-up" data-sal-delay="50" data-sal-duration="600" data-sal-easing="ease">
        {projectNodes && (
          <ProjectPreviewGrid
            title='Latest classes'
            nodes={projectNodes}
            browseMoreHref='/classes/'
          />
        )}
        </section> */}
        {/* <section>
        {postNodes && (
          <BlogPostPreviewGrid
            title='Latest blog posts'
            nodes={postNodes}
            browseMoreHref='/blog/'
          />
        )}
        </section> */}
      </Container>
      </PageTransition>
    </Layout>
  )
}

export default IndexPage
